export class Mood {
	key: string;
	moodColor: string;
	moodName: string;
	created: number;

	constructor(color: string, name: string, created = Date.now(), key = null) {
		this.moodColor = color;
		this.moodName = name;
		this.created = created;
		this.key = key;
	}
}
