import { FirebaseApp, initializeApp } from "firebase/app";
import { Database, DatabaseReference, getDatabase, push, ref, child, remove } from "firebase/database";
import { DateTime } from "luxon";
import { Mood } from "./mood";

export class Db {
	app: FirebaseApp;
	db: Database;
	moodCollection: DatabaseReference;
	maxUndoMinutes: number = 2;
	undoLogName = "undoLog";

	constructor() {
		let firebaseConfig = {
			apiKey: process.env.APIKEY,
			authDomain: process.env.AUTHDOMAIN,
			databaseURL: process.env.DATABASEURL,
			projectId: process.env.PROJECTID,
			storageBucket: process.env.STORAGEBUCKET,
			messagingSenderId: process.env.MESSAGINGSENDERID,
			appId: process.env.APPID
		};

		this.app = initializeApp(firebaseConfig);
		this.db = getDatabase(this.app);
		this.moodCollection = ref(this.db, "mood");
	}

	saveMood(color: string, name: string): void {
		const mood = new Mood(color, name);

		// save to db
		let _this = this;
		push(this.moodCollection, mood)
			.then(async function (snap) {
				// get the key so we can use it if we need to undo
				mood.key = snap.key;

				// save to undo log
				let log = _this.getUndoLog();
				log.push(mood);
				_this.setUndoLog(log);
			});
	}

	undoMood(color: string, name: string): void {
		let moodToDelete = this.getUndoMood(color, name);
		if (moodToDelete) {
			let _this = this;

			// remove from db
			remove(child(this.moodCollection, moodToDelete.key)).then(function() {
				// remove from undo log
				let undoLog = _this.getUndoLog();
				undoLog = undoLog.filter(logItem => !(logItem.moodColor === moodToDelete.moodColor && logItem.moodName === moodToDelete.moodName && logItem.key === moodToDelete.key));
				_this.setUndoLog(undoLog);
			});
		}
	}

	getUndoMood(color: string, name: string): Mood {
		let undoLog = this.getUndoLog();

		// remove items older than maxUndoMinutes
		undoLog = undoLog.filter(logItem => DateTime.fromSeconds(logItem.created / 1000) > DateTime.now().plus({ minutes: -this.maxUndoMinutes }));
		this.setUndoLog(undoLog);

		let undoMood = undoLog.find(undoItem => undoItem.moodColor === color && undoItem.moodName === name);
		return undoMood;
	}

	getUndoLog(): Mood[] {
		let undoLog: Mood[] = JSON.parse(localStorage.getItem(this.undoLogName)) || [];
		return undoLog;
	}

	setUndoLog(undoLog: Mood[]): void {
		localStorage.setItem(this.undoLogName, JSON.stringify(undoLog));
	}
}
